<template>
    <div class="content">
        <!-- <div>
            Selected: {{ selected }}
        </div> -->
        <Card
            @click.native="selectCard(card.id)"
            v-for="card in testList"
            :key="card.id"
            :data="card"
            :statusList="statusList"
            :selected="selected"
        />
        <hr>
        <Comparison :data="comparisonData" />
    </div>
</template>

<script>
import Card from "@/components/sc-journey/card";
import Comparison from "@/components/sc-journey/comparison";

export default {
    name: "sc-journey",
    components: {
        Card,
        Comparison,
    },
    data() {
        return {
            selected: '',
            testList: [
                {
                    id: '1',
                    name: 'MICROSOFT',
                    status: 'completed',
                    notofocations_count: 2,
                    refree: {
                        name: 'Alex Smith',
                        phone: '+9100000000000',
                        email: 'test.test@gmail.com',
                    },
                    checked: true,
                },
                {
                    id: '2',
                    name: 'FACEBOOK',
                    status: 'action_needed',
                    notofocations_count: 1,
                    refree: {
                        name: 'David',
                        phone: '+9100000000000',
                        email: 'test.test@gmail.com',
                    },
                    checked: false,
                },
                {
                    id: '3',
                    name: 'GOOGLE',
                    status: 'on_hold',
                    notofocations_count: 1,
                    refree: {
                        name: 'John',
                        phone: '+9100234234230',
                        email: 'test@gmail.com',
                    },
                    checked: true,
                },
            ],
            statusList: [
                {
                    id: '1',
                    label: 'Completed',
                    value: 'completed',
                    styles: {
                        backgroundColor: '#3E9F3C',
                        color: '#fff',
                    }
                },
                {
                    id: '2',
                    label: 'Action Needed',
                    value: 'action_needed',
                    styles: {
                        backgroundColor: '#F2F55A',
                        color: '#282828',
                    }
                },
                {
                    id: '3',
                    label: 'On Hold',
                    value: 'on_hold',
                    styles: {
                        backgroundColor: '#DCA310',
                        color: '#fff',
                    }
                },
            ],
            comparisonData: {
                candidate: {
                    source: 'candidate',
                    type: 'Direct',
                    date_from: '30.01.2022',
                    date_to: '30.05.2022',
                    duration: 8,
                    position: 'Developer',
                    reason: 'some reason',
                    dismissed: true,
                    documents: [
                        {
                            name: 'Document 1',
                            url: '#',
                        }
                    ],
                },
                referee: {
                    source: 'referee',
                    type: 'Direct',
                    date_from: '29.01.2022',
                    date_to: '30.05.2022',
                    duration: 8,
                    position: 'PM',
                    reason: 'some reason',
                    dismissed: false,
                    documents: [
                        {
                            id: '1',
                            name: 'Document 1',
                            url: '#',
                        }
                    ],
                },                    
            }
        }
    },
    methods: {
        selectCard(id) {
            this.selected = this.selected === id ? '' : id;
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	background-color: #EDF2F7;
}
</style>